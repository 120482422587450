var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('v-card',{staticClass:"elevation-4"},[_c('v-card-text',[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"align-self":"center","cols":"9"}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","link":"","outlined":"","to":"/roles/create"}},[_c('v-icon',[_vm._v("mdi-playlist-plus")]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.create"))+" ")])],1)],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"align-self":"center","cols":"3"}},[_c('v-btn',{staticClass:"mr-2 mt-1",attrs:{"title":_vm.$t('global_buttons.reload'),"icon":""},on:{"click":function($event){return _vm.loadData()}}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-badge',{attrs:{"content":_vm.activeFilters,"value":_vm.activeFilters,"dot":"","offset-x":"20","offset-y":"14","overlap":""}},[_c('v-btn',{staticClass:"mr-2  mt-1",attrs:{"icon":""},on:{"click":function($event){return _vm.toggleSearch()}}},[_c('v-icon',{class:{ 'primary--text': _vm.showSearch },attrs:{"title":_vm.$t('global_buttons.search')}},[_vm._v(" mdi-magnify ")])],1)],1),_c('v-expand-x-transition',[(_vm.showSearch)?_c('div',[_c('v-text-field',{ref:"search",attrs:{"placeholder":_vm.$t('global_buttons.search'),"dense":"","hide-details":""},on:{"input":_vm.search},model:{value:(_vm.filter.quick_filter),callback:function ($$v) {_vm.$set(_vm.filter, "quick_filter", $$v)},expression:"filter.quick_filter"}})],1):_vm._e()])],1)],1),_c('v-card',{staticClass:"table-container",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"footer-props":{
            'items-per-page-options': _vm.itemsPerPageOptions
          },"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pagination.size,"loading":_vm.loading,"page":_vm.pagination.number,"search":_vm.queryString,"server-items-length":_vm.totalItems,"sort-by":_vm.sort.sortBy,"sort-desc":_vm.sort.sortDesc,"dense":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "size", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.pagination, "size", $event)},function($event){return _vm.loadData()}],"update:page":[function($event){return _vm.$set(_vm.pagination, "number", $event)},function($event){return _vm.changePage()}],"update:sortBy":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.sort, "sortBy", $event)},function($event){return _vm.debouncedLoadData(100)}],"update:sortDesc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},function($event){return _vm.debouncedLoadData(100)}]},scopedSlots:_vm._u([_vm._l((_vm.headers),function(item,index){return {key:("header." + (item.value)),fn:function(ref){
          var header = ref.header;
return [_c('span',{key:index},[_vm._v(_vm._s(_vm.$t(header.text)))])]}}}),_vm._l((_vm.headers),function(header,headerIndex){return {key:("item." + (header.value)),fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{key:headerIndex,attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},'span',attrs,false),on),[_vm._v(_vm._s(item[header.value])+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item[header.value]))])])]}}}),{key:"item.photo_technical_passport",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('image-viewer',{attrs:{"src":item.photo_technical_passport,"rounded":""}}),_c('image-viewer',{attrs:{"src":item.photo_technical_passport,"rounded":""}})],1)]}},{key:"item.contract_casco_osago",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":item.contract_casco_osago,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-pdf")])],1),_c('a',{staticClass:"text-decoration-none",attrs:{"href":item.contract_casco_osago,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-pdf")])],1)])]}},{key:"item.is_active",fn:function(ref){
          var item = ref.item;
return [(item.is_active)?_c('div',[_c('v-icon',{attrs:{"color":"success","size":"16"}},[_vm._v(" mdi-checkbox-blank-circle ")]),_c('span',{staticClass:"ml-2"},[_vm._v("Да")])],1):_c('div',[_c('v-icon',{attrs:{"color":"error","size":"16"}},[_vm._v(" mdi-checkbox-blank-circle ")]),_c('span',{staticClass:"ml-2"},[_vm._v("Нет")])],1)]}},{key:"item.url",fn:function(){return [_c('image-viewer',{attrs:{"src":"https://picsum.photos/510/300?random"}})]},proxy:true},{key:"item.contract_casco_pdf",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":item.contract_osago_pdf,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-pdf")])],1)])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"to":("/roles/edit-permissions/" + (item.id)),"fab":"","link":"","text":"","title":"Редактировать права доступа","x-small":""}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-account-cog ")])],1),_c('v-btn',{attrs:{"to":("/roles/edit/" + (item.id)),"fab":"","link":"","text":"","title":"Редактировать","x-small":""}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{directives:[{name:"confirm",rawName:"v-confirm",value:({
                  title: _vm.$t('cars.modals.delete.header'),
                  message: _vm.$t('cars.modals.delete.message'),
                  callback: function () {
                    _vm.deleteRow(item.id);
                  }
                }),expression:"{\n                  title: $t('cars.modals.delete.header'),\n                  message: $t('cars.modals.delete.message'),\n                  callback: () => {\n                    deleteRow(item.id);\n                  }\n                }"}],attrs:{"title":_vm.$t('global_buttons.delete'),"color":"error","fab":"","text":"","x-small":""}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-delete ")])],1)],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-h5 mb-sm-4 pa-4 pa-sm-0 primary--text"},[_c('span',[_vm._v(" Роли пользователей ")])])}]

export { render, staticRenderFns }